<template>
    <div class="page">
        <div class="slogan flex-row justify-center">
            <div class="content-container flex-col align-center">
                {{$t('solution.p1')}}
            </div>
        </div>
        <div class="solutionType flex-row justify-center">
            <div class="content-container flex-row justify-around row">
                <span class="nav-title col-md-3" :class="{'active':nav=== 'ai'}"
                      @click="showNav('ai')">{{$t('solution.t0')}}</span>
                <span class="nav-title col-md-2" :class="{'active':nav=== 'ad'}"
                      @click="showNav('ad')">{{$t('solution.t1')}}</span>
                <span class="nav-title col-md-2" :class="{'active':nav=== 'brand'}"
                      @click="showNav('brand')"> {{$t('solution.t2')}}</span>
                <span class="nav-title col-md-2" :class="{'active':nav=== 'govern'}"
                      @click="showNav('govern')">{{$t('solution.t3')}}</span>
                <span class="nav-title col-md-2" :class="{'active':nav=== 'innovation'}"
                      @click="showNav('innovation')">{{$t('solution.t4')}}</span>
            </div>
        </div>
        <div v-if="nav==='ai'" class="solution-content-wrapper flex-row justify-center">
            <div class="content-container flex-col">
                <div class="solution-content-title text-center">{{ $t('solution.t0t') }}</div>
                <span class="solution-content-text">
                     {{ $t('solution.t0p1') }}
                </span>
                <div class="solution-content-text">
                     {{ $t('solution.t0p2') }}
                </div>
                <span class="solution-content-text">
                     {{ $t('solution.t0p3') }}
                </span>
                <img src="../assets/ourSolution/img/nurse.png" alt="" class="digitalImg" width="50%" style="margin: 0 auto">
                <div class="d-content-wrapper row">
                     <div class="col-md-6"><img src="../assets/ourSolution/img/d-1.png" alt="d-1" width="100%"></div>
                     <div class="col-md-6">
                         <div class="d-content">
                             <div class="d-content-title">{{ $t('solution.t0dt1') }}</div>
                             <div class="d-content-text">{{ $t('solution.t0dt1p1') }}</div>
                         </div>
                     </div>
                </div>
                <div class="d-content-wrapper row">
                    <div class="col-md-6">
                        <div class="d-content">
                            <div class="d-content-title">{{ $t('solution.t0dt2') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt2p1') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt2p2') }}</div>
                        </div>
                    </div>
                    <div class="col-md-6"><img src="../assets/ourSolution/img/d-2.png" alt="d-2" width="100%"></div>
                </div>
                <div class="d-content-wrapper row">
                    <div class="col-md-6"><img src="../assets/ourSolution/img/d-3.png" alt="d-3" width="100%"></div>
                    <div class="col-md-6">
                        <div class="d-content">
                            <div class="d-content-title">{{ $t('solution.t0dt3') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt3p1') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt3p2') }}</div>
                        </div>
                    </div>
                </div>
                <div class="d-content-wrapper row">
                    <div class="col-md-6">
                        <div class="d-content">
                            <div class="d-content-title">{{ $t('solution.t0dt4') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt4p1') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt4p2') }}</div>
                        </div>
                    </div>
                    <div class="col-md-6"><img src="../assets/ourSolution/img/d-4.png" alt="d-4" width="100%"></div>
                </div>
                <div class="d-content-wrapper row">
                    <div class="col-md-6"><img src="../assets/ourSolution/img/d-5.png" alt="d-5" width="100%"></div>
                    <div class="col-md-6">
                        <div class="d-content ">
                            <div class="d-content-title">{{ $t('solution.t0dt5') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt5p1') }}</div>
                            <div class="d-content-text">{{ $t('solution.t0dt5p2') }}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="nav==='ad'" class="solution-content-wrapper flex-row justify-center">
            <div class="content-container flex-col align-center">
                <span class="solution-content-title">{{ $t('solution.t1t') }}</span>
                <span class="solution-content-text1">
                     {{ $t('solution.t1p1') }}
                    <br/>
                    <br/>
                   {{ $t('solution.t1p2') }}
                    <br/>
                    <br/>
                    {{ $t('solution.t1p3') }}
                    <br/>
                    <br/>
                   {{ $t('solution.t1p4') }}
                </span>
            </div>
        </div>
        <div v-if="nav==='brand'" class="solution-content-wrapper flex-row justify-center">
            <div class="content-container flex-col align-center">
                <span class="solution-content-title">{{ $t('solution.t2t') }}</span>
                <span class="solution-content-text">
                    {{ $t('solution.t2p1') }}
                </span>
            </div>
        </div>
        <div v-if="nav==='govern'" class="solution-content-wrapper flex-row justify-center">
            <div class="content-container flex-col align-center">
                <span class="solution-content-title">{{ $t('solution.t3t') }}</span>
                <p class="solution-content-text">
                    {{ $t('solution.t3p1') }}
                </p>
            </div>
        </div>
        <div v-if="nav==='innovation'" class="solution-content-wrapper flex-row justify-center">
            <div class="content-container flex-col align-center">
                <span class="solution-content-title">{{ $t('solution.t4t') }}</span>
                <div class="solution-content-text">
                    {{ $t('solution.t4p1') }}
                </div>
            </div>
        </div>
        <div class="solution-img-wrapper flex-row justify-center">
            <div class="content-container">
                <img v-if="nav==='ad'"
                     class="solution-img"
                     referrerpolicy="no-referrer"
                     src="../assets/ourSolution/img/ad.jpg"
                />
                <img v-if="nav==='brand'"
                     class="solution-img"
                     referrerpolicy="no-referrer"
                     src="../assets/ourSolution/img/brand.jpg"
                />
                <img v-if="nav==='govern'"
                     class="solution-img"
                     referrerpolicy="no-referrer"
                     src="../assets/ourSolution/img/govern.jpg"
                />
                <img v-if="nav==='innovation'"
                     class="solution-img"
                     referrerpolicy="no-referrer"
                     src="../assets/ourSolution/img/innovation.jpg"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "OurSolution",
  data() {
    return {
      nav: 'ai'
    }
  },
  methods: {
    showNav(type) {
      this.nav = type;
    }
  }
}
</script>

<style scoped lang="css" src="../assets/common/common.css"/>
<style scoped lang="css" src="../assets/ourSolution/index.css"/>
