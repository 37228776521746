module.exports = {
  aboutUs: {
    aboutUs: "关于我们",
    t1:'自主创新为根本',
    t2:'技术创新为目标',
    t3:'质量上乘为基础',
    t4:'服务到位为保障',
    p1:'迪捷思（DisplayJets）是北京思普时代技术有限公司旗下子品牌，专注与智能商显系统的SaaS平台服务和整体产品解决方案的提供商。迪捷思（DisplayJets）依托高新技术为数字屏幕打造一站式管理和运营解决方案，让屏幕拥有智能管控、信息发布、趣味互动、展示运用等功能；为了提供更好的服务。迪捷思（DisplayJets）还提供私有化部署解决方案，满足数字屏幕个性化功能定制开发，并提供屏幕代运营服务，专业团队全程贴身服务。',
    p2:'公司核心团队由互联网IT专家于2010年联手创立，现已连接全国200个城市线下电子屏，覆盖连锁门店、交通出行、餐饮购物、文旅景区、商务办公、社区住宅、智慧酒店、智慧医疗八大生活场景。',
    contactUs:'联系我们',
    partner:'合作伙伴',
    ourHonor:'我们的荣誉',
    tel:'电话 : 400-690-8766  010-57108766',
    email:'邮箱 : info@siptimes.com',
    address:'地址 : 北京市西城区裕民路18号北环中心1708',
  },
  webHeader:{
    home:'首页',
    productCenter:'产品中心',
    solution:'解决方案',
    cooperationCase:'合作案例',
    aboutUs:'关于我们',
    helpCenter:'帮助中心'
  },
  home:{
    companyVision :"公司愿景",
    companyMission:'公司使命',
    p1:'用数字技术驱动世界进步',
    p2:'助力每个组织成就不凡',
    p3:'构建万屏互联的智能世界',
    t1:'DisplayJets的优势',
    icon1:'云计算',
    icon2:'视频',
    icon3:'音频',
    icon4:'边缘计算',
    icon5:'安全',
    s1t:'先进技术',
    s1p:'基于云计算、边缘计算、AI、IoT等先进技术的新一代商显智能操作系统——DisplayJets。',
    s2t:'打造商显行业的基础软件平台',
    s2p:'产品致力于打造商显行业的基础软件平台，支撑行业软件的发展，助力产业升级，迎接云计算、AI、5G 时代的到来。',
    s3t:'快速制作精彩的数字节目',
    s3p:'DisplayJets以前瞻性、拓展性、实用性为设计理念，采取集中控制，统一管理的方式，可以快速制作精彩的数字节目（视频、音频、WEB应用），发布到终端大屏幕上播放，与受众进行高品质的沟通以及互动。',
    s4t:'功能强大、操作简单、安全稳定',
    s4p:'DisplayJets功能强大、操作简单、安全稳定，能为用户快速建立优质的媒体播控智能应用，同时降低系统构建成本及复杂度。',
    b2t:'专业的定制化解决方案',
    b2p:'我们的客户遍布除西藏、香港、澳门、台湾外全部省区，拥有技术过硬的全国性支持团队，服务门店近万家。覆盖了机场、餐饮、高铁、医疗、政企、数字传媒的多个行业。 同时还为客户提供专业的定制化解决方案。',
    b3t:'自主研发，国产化安全可靠',
    b3p:'迪捷思数字媒体智能操作系统，平台代码、终端代码完全自主研发，并通过了麒麟、统信等一系列国产化互兼容认证，保障平台在政府、企业等安全性需求。',
    b5t:'合作伙伴'
  },
  footer:{
    ContactInformation:'联系方式',
    tel:'电话 : 400-690-8766  010-57108766\n',
    email:'邮箱 : info@siptimes.com',
    address:'地址 : 北京市西城区裕民路18号北环中心1708',
    officialCode:'官方公众号',
    app:'手机App'
  },
  productCenter: {
    t1:'迪捷思播控软件',
    t2:'迪捷思硬件设备',
    b1t:'迪捷思播控软件',
    b1p:'迪捷思DisplayJets数字媒体智能播控系统是基于云计算、边缘计算、IoT、AI、5G等先进技术，构建而成的新一代数字媒体智能播控系统， 涵盖从数字节目的制作、编排、审核、发布到大屏播放各流程，通过对播控内容和设备的集中控制和统一管理，完美实现与受众高品质 沟通和互动。系统功能强大，操作简单、安全稳定，支持云部署和本地部署，满足不同用户需要。系统与不同行业业务深度结合，可以升级构建特定细分领域的行业版商显智能应用系统，比如智慧病房等等。',
    it1:'品牌连锁',
    it2:'机场地铁',
    it3:'智慧病房',
    it4:'政企数媒',
    it5:'广告传媒',
    it6:'信创产品',
    h1:'商用机型',
    h2:'工程机型',
    h3:'沉浸式机型',
    h4:'智能传感器'
  },
  solution:{
    p1:'致力于智能商显领域边缘计算基础平台研发及应用，为机场、地铁、餐饮、零售等多个行业提供专业的互联网数字媒体智能播控解决方案。',
    t0:'AI虚拟数字人',
    t1:'广告传媒',
    t2:'品牌连锁',
    t3:'政企数媒',
    t4:'信创方案',
    t0t:'迪捷思AI虚拟数字人',
    t1t:'广告业商显一体化解决方案',
    t0p1:'迪捷思AI虚拟数字人利用AI深度学习，大数据、物联网技术，迪捷思AI虚拟数字人呈现一种全新的人机交互方式， 作为当前应用广泛的自助机替代产品或具有一定专业知识的虚机数字机器人， 顾客通过与屏幕中虚拟数字人对话，无需接触即可办理各种业务，交互自然，功能强大，大大提升用户体验和业务办理效率。',
    t0p2:'广泛用于医院、购物中心、政务大厅、机场交通枢纽、酒店、营业厅等场所。',
    t0p3:'迪捷思AI虚拟数字人有以下三方面特征：一是拥有人的外观，具有特定的相貌、性别和性格等人物特征； 二是拥有人的行为，具有用语言、面部表情和肢体动作表达的能力；三是拥有人的思想，具有识别外界环境、并能与人交流互动的能力。',
    t0dt1:'系统框架',
    t0dt1p1:'迪捷思 AI虚拟数字人系统由人物形象、语音生成、动画生成、音视频合成显示、交互、专业智慧引擎及人工辅助等7个模块构成。',
    t0dt2:'非交互类虚拟数字人系统运作流程',
    t0dt2p1:'迪捷思AI虚拟数字人交互模块为扩展项，根据其有无，可将数字人分为交互型数字人和非交互型数字人。',
    t0dt2p2:'非交互型数字人体统的运作流程如图所示。 系统依据目标文本生成对应的人物语音及动画，并合成音视频呈现给用户。',
    t0dt3:'智能驱动型虚拟数字人运作流程',
    t0dt3p1:'交互型数字人根据驱动方式的不同可分为智能驱动型和真人驱动型。',
    t0dt3p2:'智能驱动型数字人可通过智能系统自动读取并解析识别外界输入信息，根据解析结果决策数字人后续的输出文本，然后驱动人物模型生成相应的语音与动作来使数字人跟用户互动。 该人物模型是预先通过AI技术训练得到的，可通过文本驱动生成语音和对应动画，业内将此模型称为TTSA（Text To Speech & Animation）人物模型。',
    t0dt4:'真人驱动型虚拟数字人运作流程',
    t0dt4p1:'交互型数字人根据驱动方式的不同可分为智能驱动型和真人驱动型。',
    t0dt4p2:'真人驱动型数字人则是通过真人来驱动数字人，主要原理是真人根据视频监控系统传来的用户视频，与用户实时语音，同时通过动作捕捉采集系统将真人的表情、动作呈现在虚拟数字人形象上，从而与用户进行交互。',
    t0dt5:'技术架构',
    t0dt5p1:'虚拟数字人分为2D、3D 数字人，3D 数字人需要额外使用三维建模技术生成数字形象，信息维度增加，所需的计算量更大。',
    t0dt5p2:'每类数字人包括用于数字虚拟人制作、交互的五大技术模块，即人物生成、人物表达、合成显示、识别感知、分析决策等模块。 其中，人物表达包括语音生成和动画生成。动画生成则包含驱动（动作生成）和渲染两大部分。',
    t1p1:'1.精准定位目标受众：通过大数据分析和人工智能技术，程序化购买可以精准地定位目标受众，提高广告投放的精准度和效果。',
    t1p2:'2.自动化投放：程序化购买可以自动化完成广告投放的过程，减少人工干预，提高广告投放效率。',
    t1p3:'3.实时监测和优化：程序化购买可以实时监测广告投放效果，并根据数据进行优化，提高广告投放ROI。',
    t1p4:'4.节约成本：程序化购买可以减少人力和时间成本，提高广告投放效率，降低广告投放成本。',
    t2t:'品牌连锁店企业商显应用解决方案',
    t2p1:'在线下门店需转型的当下，年轻一代都喜欢科技感十足的体验，零售行业要怎么抓住顾客， 提升客户黏性是首先需要考虑的。门店利用数字屏幕打造智慧门店，让顾客享受好物的同时，感受数字化带来的便利服务。',
    t3t:'智能楼宇多媒体播控系统解决方案',
    t3p1:'数字政务、企业的数字化转型正如火如荼地开展，各类政企充分利用智能楼宇内分布于众多空间的各类大屏幕， 借助迪捷思DisplayJets数字媒体智能播控系统，开展相关综合信息的发布监控、宣传展示和互动触达， 打造数字时代的政务展播厅、企业展厅、党建宣传室等等，构建充满科技感的高效、自动、人性化的现代政务和办公智能大楼。',
    t4t:'信息技术应用创新(信创)解决方案',
    t4p1:'自主可控已经成为政府、金融、交通、能源等关键领域数字化转型的必要条件， 迪捷思DisplayJets基于多种国产信创技术架构平台，兼容多种国产操作系统（麒麟OS、统信UOS）和国产服务器、终端硬件（海光、兆芯）, 打造的国产商显智能播控系统，填补了商显播控信创产品的空白。',
  },
  cooperationCase:{
    t1:'哈根达斯：传统门店转型升级',
    t2:'太原地铁：打造绿色、人文、智慧地铁',
    t3:'鄂尔多斯机场：新机场数字媒体转型升级',
    t4:'华尔道夫：酒店沉浸式新体验',
    t5:'眉州东坡：数字餐饮门店升级',
    t6:'箭牌卫浴：国产品牌智慧门店新范式',
    t7:'古北水镇景区：网红景区的数字化转型',
    t1t1:'项目背景',
    t1t2:'项目痛点',
    t1t3:'实施方案',
    t1p1:'打破门店传统大屏U盘播放，向数字化门店转型升级',
    t1p2:'1.U盘播放更新难度大、时效差；2.无法统一管理；3.维护成本高；4.营销活动信息传递慢。',
    t1p3:'DisplayJets平台的开放性，兼容第三方硬件平台，实现平台统一化运营，提升哈根达斯品牌影响力的同时，也节省了门店的人力、物力，与此同时营销活动及时传播到全国各门店，提高了传播效率。',
    t1p4:'DisplayJets/统一播控/第三方硬件兼容/背景音乐',
    t2t1:'项目背景',
    t2t2:'项目需求',
    t2t3:'实施方案',
    t2t4:'功能解析',
    t2p1:'太原地铁2号线以打造“绿色地铁、人文地铁、智慧地铁”为目标，全线铺设多媒体设备显示屏近2000块，希望通过数字化多媒体全场景的表达方式，打破物理空间局限，实现国内第一个以文化为主导，“全媒体、全场景”一体化运营的地铁；全国第一个有编播系统的地铁；全世界第一个有故事系统的地铁。',
    t2p2:'1.一个能管理太原地铁2号线全线所有屏幕的平台； 2.平台稳定，可同时支持多种显示硬件； 3.平台兼容性强，可支持多系统设备；',
    t2p3:'通过使用DisplayJets商显操作系统，对太原地铁实施本地化安全布署，进行实现大屏的综合管理系统，对全线23个车站所有大屏进行播控，实现多种显示终端和多系统的兼容，达到平台稳定的同时利用平台的各种功能，成“全媒体、全场景”一体化运营。',
    t2p4:'本地部署（私有云服务）/第三方系统对接/第三方硬件兼容/全线统一管理',
    t2p5:'同时兼容多种显示终端，包括LED屏、LCD液晶屏、透明屏、滑轨屏、投影机；同时兼容多系统设备，Windows、Android和Linux系统；统一管理全线的，出入口梯媒、导视屏、站内梯媒、转角屏、主点位创意屏。',
    t3t1:'项目背景',
    t3t2:'项目需求',
    t3t3:'实施方案',
    t3t4:'场景解析',
    t3t5:'同步播放',
    t3t6:'直播服务',
    t3t7:'统一管理',
    t3p1:'机场广告媒体的电子化和互联网化是不可阻挡的趋势，目前部分机场的电子媒体总量已达到50%，有的机场甚至达到了70%。鄂尔多斯机场希望在信息量和传播影响力方面，将数字媒体和传统广告媒体互为补充、形成合力，更好的满足广告主的迫切需求，发挥组合优势，构建线上线下双平台，不仅能强化线下媒体的体验感、交互性，更能在发布时间、频次、内容上与线上做有效的配合，各显优势。',
    t3p2:'1.大屏，广告机，系统不统一，无法实现统一化管理； 2.原有平台只能满足基本的展示需求，而鄂尔多斯机场希望能具备扩展性，无需进行工程化开发。',
    t3p3:'DisplayJets本地布署（私有云服务）/ 第三方硬件兼容/同步播放/直播服务',
    t3p4:'到达层：立式广告机 出发层：三面屏、立式双面屏、立式广告机 大厅：立式广告机',
    t3p5:'不同区域的同步播放，给游客带来视觉上的冲击，同时吸引了广告主的投放意愿。',
    t3p6:'重大活动直播服务：各大节日（春节晚安、国庆等）统一直播，重大接待直播服务。',
    t3p7:'解决了原有不同系统的多种控制，现在通过DisplayJets系统统一管理机场所有不同类型的屏幕终端，提高工作效率的同时，也提升的播放效果等。',
    t4t1:'项目背景',
    t4t2:'项目需求',
    t4t3:'实施方案',
    t4p1:'作为顶级奢侈品牌，华尔道夫希望打造一个神秘又玄幻的梦幻星空，带给宾客极致体验，于是浪漫的华尔道夫梦幻星空下午茶诞生了。让科技助力，借助DisplayJets数字媒体智能播控系统，将奥妙的太空近距离呈现在宾客面前。每个桌子上方架设了一个投影灯，投影灯搭配有温度感应器，可以探测桌面饮品的不同温度，根据桌面不同饮品和食品的温度做出多样化的互动反应，于是多彩神秘的星云及漫天星河飘动在桌面上，为客人们烘托出浪漫缤纷又神秘梦幻的星空氛围，让人有如置身于太空之中。',
    t4p2:'1.温感互动 2.远程播控统一管理 3.手机app远程控制',
    t4p3:'通过DisplayJets商显操作系统的开放性，可集成和兼容第三方硬件，在定制开发后，实现温度传感效果。',
    t4p4:'DisplayJets /LWA应用/第三方硬件集成/第三方硬件兼容/远程管理/定制开发设计/手机APP播控',
    t5t1:'项目背景',
    t5t2:'项目痛点',
    t5t3:'实施方案',
    t5p1:'眉州东坡作为北京冬奥赞助商的新型智慧餐饮企业，数字门店升级迫在眉睫。',
    t5p2:'1.传统U盘播放；2.无法统一管理；3.无法达到同步播放效果，门店联屏播放混乱；4.作为北京冬奥赞助商。',
    t5p3:'DisplayJets/统一播控/第三方硬件兼容/电视直播',
    t6t1:'项目背景',
    t6t2:'项目痛点',
    t6t3:'实施方案',
    t6t4:'场景解析',
    t6p1:'作为老牌国产卫浴品牌，箭牌卫浴希望通过新的方式推动公司的营销活动，提升效果作为卫浴新零售战 略中做“第一个吃螃蟹的人”，数字门店从0到1的搭建迫在眉睫。',
    t6p2:'1.线下线上原属完全分离的两个渠道，需要从0搭建连接； 2.线下实体店铺日渐丧失吸引力，需要有力的引流策略。',
    t6p3:'DisplayJets /第三方数据对接/第三方硬件兼容/场景app',
    t6p4:'店内-LED屏 抖音直播活动 主题游戏定制 大转盘',
    t7t1:'项目概述',
    t7t2:'方案策略',
    t7t3:'功能解析',
    t7t4:'实施方案',
    t7p1:'古北水镇作为北京优秀的度假圣地之一，为每位游客提供亲情的游玩体验。随着数字媒体的发展，古北水镇致力于打造最好的游客体验环境，逐步在景区内进行数字化媒体改造。',
    t7p2:'使用DisplayJets商显操作系统及硬件设备，实现本地化安全部署。对所有大屏进行统一播控管理，同时事项所有大屏同步播放的效果。',
    t7p3:'大屏位于游客服务中心大厅区域，时辐射人群最广的位置。通过迪捷斯智屏控制系统，可以事项同步播放、轮播、插播、定时播放等多种方式，展示品牌活动信息，从视觉上吸引游客。',
    t7p4:'游客服务中心建设宣传大屏，达到信息传递游客的效果。 大屏幕统一管理。 屏幕同步播放。 北翟华安全部署，事项控制中心直接管理。',
  },
  helpCenter:{
    t:'下载中心',
    t1:'播放软件下载',
    t2:'App下载',
    t3:'用户使用手册',
    download: '下载',
    t2t: '扫描下方二维码即可下载',
    t3t1: 'DisplayJets用户使用手册(v7.0)',
    t3t2: 'DisplayJets合作伙伴预授权操作(v2.0)',
  }
};
